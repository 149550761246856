import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from "@angular/router";
import {inject} from "@angular/core";
import {StateService} from "./core/state.service";
import {environment} from "../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {CommunicationService} from "./core/communication.service";
import {ThemeService} from "./core/theme.service";
import {UserInfo} from "./userInfo";

export const consentGuard: CanActivateFn = async (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {

  const stateService = inject(StateService);
  const communicationService = inject(CommunicationService);
  const httpClient = inject(HttpClient);
  const themeService = inject(ThemeService);
  let router = inject(Router);

  if (stateService.authenticated) {
    return true;
  }

  const anonymousParam = route.queryParamMap.get('anonymous') === 'true';
  let queryParamUserId = route.queryParamMap.get('userId');
  queryParamUserId = queryParamUserId === 'null' || queryParamUserId === '0' ? null : queryParamUserId;

  stateService.userId = stateService.userId || queryParamUserId || ('test-' + new Date().toISOString().split('T')[0]);
  stateService.tenantId = stateService.tenantId || route.queryParamMap.get('clientId') || environment.tenantId;
  stateService.testMode = route.queryParamMap.get('testMode') === 'true' || !environment.production;
  stateService.token = route.queryParamMap.get('token') || null;
  stateService.isNative = route.queryParamMap.get('isNative') === 'true';

  communicationService.logToApp('userId: ' + stateService.userId);
  communicationService.logToApp('tenantId: ' + stateService.tenantId);
  communicationService.logToApp('testMode: ' + stateService.testMode);
  communicationService.logToApp('isNative: ' + stateService.isNative);
  communicationService.logToApp('token: ' + stateService.token);

  async function fetchUserDetails() {
    const res = await httpClient.get<UserInfo>(environment.apiUrlNew + '/user').toPromise();
    console.log('AUTH', res);
    stateService.authenticated = true;
    stateService.language = res?.language || 'it';
    stateService.user = res;
    communicationService.logToApp('User fetched successfully');
  }

  async function createTestUser() {
    return await httpClient.post<{ token: string }>(environment.apiUrlNew + '/client/activate/user',
      {
        externalId: stateService.userId,
        test: stateService.testMode,
        anonymous: anonymousParam
      },
      {
        headers: new HttpHeaders({
          'X-Client-Secret': environment.testClientSecret
        })
      }).toPromise();
  }

  if (!stateService.authenticated) {
    if (stateService.token) {
      await fetchUserDetails();
    } else {
      const tokenRes = await createTestUser();
      if (tokenRes) {
        stateService.token = tokenRes.token;
        await fetchUserDetails();
      }
    }
  }

  themeService.injectTheme();
  document.querySelector('#app-loading-spinner')?.remove();

  if (!stateService?.user?.termsAcceptedTs || anonymousParam || stateService?.user?.anonymous) {
    return router.navigate(['/consent']);
  }
  return true;
};
